.theRadioAddress label {
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 150px;
	margin-right: 1rem;
	display: block;
	border: 2px solid transparent;
}
.theRadioAddress input[type="radio"]:checked + label {
	border: 2px solid #194234;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 1rem;
	display: block;
}

.theRadioAddress input[type="radio"] {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.checkmarkAddress {
	align-items: center;
	justify-content: center;
	cursor: pointer;
	display: block;
	padding: 1rem;
	border: 2px solid #EAEAEA;
	/* box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.1); */
	border-radius: 6px;
	margin-bottom: 1rem;
}

/* ON MOOUSEOVER, ADD A GREY BACKGROUND COLOR */
.theRadioAddress:hover input ~ .checkmarkAddress {
}

/* WHEN THE RADIO BUTTON IS CECKED, ADD A BACKGROUND COLOR */
.theRadioAddress input:checked ~ .checkmarkAddress {
	border: 2px solid #194234;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	/* margin-right: 1rem; */
	display: block;
}