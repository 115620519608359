htnl {
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

* {
  outline: 0 !important;
}

.Mui-focusVisible {
  background-color: transparent !important;
}

.MuiAccordion-root {
  box-shadow: unset !important;
  background-color: #fff !important;
  border-bottom: 1px solid #707070;
}

.MuiAccordion-root:first-of-type {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.MuiAccordion-root:before {
  background-color: transparent !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: unset !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

textarea {
  padding: 0 !important;
}

.MuiTableHead-root {
  background: #ecebef !important;
}

.theTextDots {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

iframe{
  width: 100%;
}

@media screen and (min-width: 768px) {
  iframe{
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  iframe{
    width: 560px;
  }
}